import React from 'react';
import { Package } from '../../Classes/Package';
import Layout from '../../components/Layout/layout';
import Silver from '../../Views/Website/Packages/Silver/Silver';

const SilverPage: React.FC = (props: any) => {
  const Package: Package = props.pageContext.Package;

  return (
    <Layout>
      {Package ? <Silver hideBanner={false} Packages={[Package] as Package[]} /> : <></>}
    </Layout>
  );
};

export default SilverPage;
